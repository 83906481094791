.ProductsList {
  width: 100%;
  /* height: 100vh; */
  background-color: white;
  padding: 30px;
}

.itemListProduct {
  /* width: 90%; */
 
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin:  auto;
}

.bg-img{
  width: 65%;
}

.description-product {
  background-color: rgb(231, 151, 2);
  width: 70%;
  height: 100%;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  gap: 20px;
  text-align: center;
  text-transform: uppercase;
  text-align: justify;
  color: #000000d7;
}

.description-product h2 {
  font-size: 50px;
  text-transform: uppercase;
  font-family: 'Custom-font';
}

.description-product p{
  font-family: 'Custom-font-magnum';
  
  font-weight: 800;
}

/* CSS */
.button-50 {
  text-transform: uppercase;
  appearance: button;
  background-color: #000000d7;
  background-image: none;
  border: 1px solid #000000d7;
  border-radius: 4px;
  box-shadow: #fff 4px 4px 0 0, #000000d7 4px 4px 0 1px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: ITCAvantGardeStd-Bk, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0 5px 10px 0;
  overflow: visible;
  padding: 12px 40px;
  text-align: center;
  text-transform: none;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

.button-50:focus {
  text-decoration: none;
}

.button-50:hover {
  text-decoration: none;
}

.button-50:active {
  box-shadow: rgba(0, 0, 0, 0.125) 0 3px 5px inset;
  outline: 0;
}

.button-50:not([disabled]):active {
  box-shadow: #fff 2px 2px 0 0, #000 2px 2px 0 1px;
  transform: translate(2px, 2px);
}

@media (min-width: 768px) {
  .button-50 {
    padding: 12px 50px;
  }
}

@media screen and (max-width: 600px) {

  .bg-img{
    width: 100% ;
  }
  .itemListProduct {
    
    flex-direction: column;
  }

  .itemListProduct div {
    width: 100%;
  }

  .description-product {
    width: 100%;
  }

  .description-product h2 {
    font-size: 40px;
  }
}
