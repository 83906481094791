/* @import "~video-react/styles/scss/video-react"; */
.Section {
  height: 100vh;
  padding-top: 80px;
  width: 100%;
  overflow: hidden;
  background-color: rgb(250, 248, 248);
  position: relative;
  /* background-image: url('./orage_loop.mp4');
    background-position: center;
    background-size: cover; */
}
.bg-main{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  /* z-index: 99; */
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 200px;
  color: white;
  font-family: "Custom-font", sans-serif;
}

.player {
  width: 100%;
  height: 100vh;
}

@media screen and (max-width: 600px) {
  .overlay {
    font-size: 100px;
    /* height: 150px; */
    padding-top: 89px;
  }
  .player {
    height: 150px;
  }
}
