.Header {
    width: 100%;
    height: 80px;
    background-color: rgba(250, 250, 250, 0.8);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    position: fixed;
    top: 0;
    z-index: 999;
}

.Header .logo{
    font-family: 'Custom-font', sans-serif;
    color: black;
    font-size: 35px;
     cursor: pointer;
}

.Header ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    gap: 20px;
}

.Header ul li {
    list-style: none;
    cursor: pointer; 
    font-family: 'Custom-font';
}