.Contatti {
  height: 100vh;
  color: #d6d6d6;
  font-family: serif;
  position: relative;
  /* background-color: #2e2e2e; */
  padding: 80px 0;
}

.Contatti h1 {
  text-align: center;
  /* margin: 40px 0; */
  padding: 40px 0;
  font-family: 'Custom-font';
  font-size: 60px;
}

.section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-bottom: 20px; */
  /* background: linear-gradient(to right, #43434362 0%, rgba(0, 0, 0, 0.438) 100%); */
  /* border-radius: 10px; */
  overflow: hidden;
  
}

.section.reverse {
  flex-direction: row-reverse;
}

.section .text {
  font-size: 20px;
  width: 45%;
  padding: 20px;
  box-sizing: border-box;
  line-height: 38px;
  font-family: 'Custom-font-helvetica';
  font-weight: 800;
}

.section .image {
  width: 60%;
  /* height: 100%; */
 
  /* padding: 20px; */
  box-sizing: border-box;
}

.section .image img {
  width: 100%;
  /* height: 100%; 
   object-fit: contain; */
}

@media (max-width: 768px) {
  .section, .section.reverse {
    flex-direction: column;
  }

  .Contatti h1 {
    text-align: center;
    font-size: 30px;
    margin: 20px 0;
    font-family: 'Custom-font';
  }

  .section .text, .section .image, .section.reverse .text, .section.reverse .image {
    width: 100%;
    padding: 10px;
    font-size: 15px;
    line-height: 20px;
  }
}