.Btn {
    position: absolute;
    bottom: 20px;
    right: 50%;
    
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 50%;
    background-color: rgb(27, 27, 27);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    /* position: relative; */
    transition-duration: .3s;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.11);
    z-index: 999;
  }
  
  .svgIcon {
    transform: rotate(180deg);
    fill: rgb(214, 178, 255);
  }
  
  /* .icon2 {
    width: 18px;
    height: 5px;
    border-bottom: 2px solid rgb(182, 143, 255);
    border-left: 2px solid rgb(182, 143, 255);
    border-right: 2px solid rgb(182, 143, 255);
  } */
  
  
  
  .tooltip::before {
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    background-color: rgb(12, 12, 12);
    background-size: 1000%;
    background-position: center;
    transform: rotate(45deg);
    left: -5%;
    transition-duration: .3s;
  }
  
  .Btn:hover .tooltip {
    opacity: 1;
    transition-duration: .3s;
  }
  
  .Btn:hover {
    background-color: rgb(150, 94, 255);
    transition-duration: .3s;
  }
  
  .Btn:hover .icon2 {
    border-bottom: 2px solid rgb(235, 235, 235);
    border-left: 2px solid rgb(235, 235, 235);
    border-right: 2px solid rgb(235, 235, 235);
  }
  
  .Btn:hover .svgIcon {
    fill: rgb(255, 255, 255);
    animation: slide-in-top 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }
  
  
  